import React, {useState} from 'react';
import Modal from 'components/UI/Modal/Modal';
import useToggle from 'hooks/useToggle';
import { Fade, Slide } from "react-awesome-reveal";

import './style.scss';
import { SocialIcon } from 'react-social-icons'
import YouTube from 'react-youtube';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";

import Logo from 'assets/images/rethink-logo.png';
import dogs from 'assets/images/dogs.png';
import dogsLeft from 'assets/images/dogs-left2.png';
import dogsRight from 'assets/images/dogs-right2.png';

import petsecureImg from 'assets/images/petsecure.png';
import trupanionImg from 'assets/images/trupanion.png';
import fetchImg from 'assets/images/fetch.png';

const Home = (props) => {
  //  const SITE_URL = process.env.REACT_APP_AWS_POINT;
    const SITE_URL = 'https://youtu.be/Ep-_JXHRbUU';
    const {lang, isMobile} = props;
    //const [showModal, setShowModal] = useState(false);
    const [isToggled, toggle] = useToggle(false);

      const opts = {
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
          modestbranding: 1,
          showinfo:0,
          rel: 0
        },
      };

    
    console.log('home props ', props);

    return (
        <div className="blockwrap home">
          
                <div className="content">
                    <section className="hero">
                        <a href="https://rethinkideas.com/" target="_blank" rel="noreferrer"><img className="logo" src={Logo} alt="logo" /></a>
                        <Fade delay="75" triggerOnce>
                            <div className="title">
                                <h1>Our Pets deserve <span>to be protected.</span></h1>
                            </div>
                        </Fade>
                      
                        <div className="dogs">

                            <div><Slide direction="left" triggerOnce delay="140"><img className="dogs-left" src={dogsLeft} alt="dogs" /></Slide></div>
                            <div><Slide direction="right" triggerOnce delay="140"><img className="dogs-right" src={dogsRight} alt="dogs" /></Slide></div>
                          {/* <img className="dogs-left" src={dogs} alt="dogs" />
                            <img className="dogs-right" src={dogs} alt="dogs" /> */}
                        </div>
                       
                        
                    </section>
                    <section className="body">
                        <div className="container">
                            <p className="main-text">They’re always there for us, so it’s time we did something important for them. From now on, every Rethinker’s pet is covered with Pet&nbsp;Insurance.</p>  
                        </div>
                        <div className="container padding">
                            <div className="video-wrapper">
                                <div className="video-copy-share">
                                    <h2>Let’s make pet insurance coverage part of every agency's benefit&nbsp;package.</h2>
                                    <br />
                                    <div className="share-wrapper">
                                        <span>Share to your network:  &nbsp;</span><br />
                                        <FacebookShareButton quote="All Rethink pets are now covered. Let's make pet insurance part of every agency's benefit package. Learn more at RethinkPetInsurance.com." url={SITE_URL}><SocialIcon as="div" className="social-icon-class" bgColor="#ffffff" fgColor="#7d7d7d" network="facebook"  /></FacebookShareButton>
                                        <TwitterShareButton url={SITE_URL} title="All Rethink pets are now covered. Let's make pet insurance part of every agency's benefit package."> <SocialIcon as="div" className="social-icon-class" bgColor="#ffffff" fgColor="#7d7d7d" network="x" />
                                        </TwitterShareButton>
                                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${SITE_URL}`} target="_blank" rel="noreferrer"><SocialIcon as="div" className="social-icon-class" bgColor="#ffffff" fgColor="#7d7d7d" network="linkedin" /></a>
                                    </div>
                                </div>
                                <div className="video-container">
                                    <div className="youtube-container">
                                        <YouTube videoId="Ep-_JXHRbUU" opts={opts} className="youtube" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="pet-insurance">
                        <h2>Get started today:</h2>
                        <div className="insurance-wrapper">

                            <ul>
                                <li>  <a href="https://www.petsecure.com/" target="_blank" rel="noreferrer"><img src={petsecureImg} alt="petsecure" /></a></li>
                                <li> <a href="https://www.trupanion.com/" target="_blank" rel="noreferrer"><img src={trupanionImg} alt="trupanion" /></a></li>
                                <li>  <a href="https://www.fetchpet.com/canada" target="_blank" rel="noreferrer"><img src={fetchImg} alt="fetch" /></a></li>
                            </ul>
                          
                        </div>
                    </section>
                    <footer>
                        <SocialIcon target="_blank" className="social-icon-class" bgColor="#ffffff" fgColor="#7d7d7d" url="https://facebook.com/rethinkcanada" />
                        <SocialIcon target="_blank" className="social-icon-class" bgColor="#ffffff" fgColor="#7d7d7d" url="https://x.com/rethinkideas" />
                        <SocialIcon target="_blank" className="social-icon-class" bgColor="#ffffff" fgColor="#7d7d7d" url="https://www.linkedin.com/company/rethink" />
                        <SocialIcon target="_blank" className="social-icon-class" bgColor="#ffffff" fgColor="#7d7d7d" url="https://www.instagram.com/rethinkideas/" /><br />
                        <span>Illustration from Vecteezy.com</span>

                    </footer>
                </div>
                
           

        </div>
        
    )
}
export default Home;